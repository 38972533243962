<template>
  <div class="page-not-found-page">
    <div class="page-content">
      <v-row no-gutters class="page-content-header">
        <v-col cols=12 class="text-center">
        <!-- <label class="title pa-3 ma-0 text-center white--text text-capitalize d-block full-width font-weight-bold">{{ $t('label.error404') }}</label> -->
        <router-link :to="{ name: routeName.HOME, params:{lang: this.$route.params.lang}}">
          <img src="/static/svg/bb88_logo_w_sponsor.svg" alt width="200" height="35" style="float:center;"/>
        </router-link>
        </v-col>
      </v-row>
      <v-row no-gutters class="page-content-body text-center">
        <v-col cols="12">
          <div class="mb-2">
            <p class="font-weight-bold subtitle-1 mb-1">{{ $t('message.errorPageMessage') }}</p>
            <p class="error-page-error-code ma-1">404</p>
            <p class="body-2 mb-0">{{ $t('message.error404_1') }}</p>
            <p class="body-2 mb-0">{{ $t('message.error404_2') }}</p>
          </div>
          <v-row>
            <!-- <v-col cols="6">
              <div class="game-selection">
                <v-btn link text tile height="auto" width="100%" :to="{ name: routeName.SPORTSBOOK }">{{ $t('label.sb') }}</v-btn>
              </div>
            </v-col>
            <v-col cols="6">
              <div class="game-selection">
                <v-btn link text tile height="auto" width="100%" :to="{ name: routeName.CASINO }">{{ $t('label.ld') }}</v-btn>
              </div>
            </v-col>
            <v-col cols="12">
              <div class="game-selection">
                <v-btn link text tile height="auto" width="100%" :to="{ name: routeName.SLOT }">{{ $t('label.rng') }}</v-btn>
              </div>
            </v-col> -->
          </v-row>
          <v-row class="mt-5 mb-2">
            <v-col cols=12>
            <app-button class="error-page-button" :maxWidth="200" :title="$t(`button.goHome`)" :action="goHome"></app-button>
            </v-col>
          </v-row>
          <v-row no-gutters justify="center" v-show="false">
            <v-col cols="auto">
              <img class="footer-icon" src="/static/svg/btm-fb.svg" alt/>
            </v-col>
              <v-col cols="auto">
              <img class="footer-icon" src="/static/svg/btm-insta.svg" alt/>
              </v-col>
              <v-col cols="auto">
              <img class="footer-icon" src="/static/svg/btm-twitter.svg" alt/>
              </v-col>
              <v-col cols="auto">
              <img class="footer-icon" src="/static/svg/btm-yt.svg" alt/>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import { ROUTE_NAME } from '@/constants/route.constants'
export default {
  name: 'errorPageNotFound',
  data: () => ({
    routeName: ROUTE_NAME
  }),
  methods: {
    // openLiveChat() {
    //   window.open('/livechat', 'Live Chat', 'width=350,height=600')
    // },
    goHome(){
      this.$router.push({
        name: ROUTE_NAME.HOME,
        lang: this.$route.params.lang
      })
    }
  }
}
</script>
<style lang="scss">
.page-not-found-page {
  .page-content {
    text-align: center;
    position: absolute;
    max-width: 500px;
    width: 90%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .page-content-header {
      border-top-left-radius: 10px !important;
      border-top-right-radius: 10px !important;
      background-color: #fff;
      position: relative;
    }
    .page-content-body {
      padding: 20px;
      background-color: #fff;
      border-bottom-left-radius: 10px !important;
      border-bottom-right-radius: 10px !important;
      .error-page-error-code {
        letter-spacing:8px;
        font-size: 150px;
        font-weight: bold;
        color: #FDDD04;
      }
    }

    .game-selection {
      border-radius:5px;
      background-image: linear-gradient(to left, var(--v-primary-base), var(--v-primary_3-base));

      .v-btn__content {
        font-size: 22px;
        padding: 25px 0px;
        font-weight: bolder;
        color: white;
      }
    }
  }
  .error-page-button{
    width: 200px !important;
  }
}

@media (max-width: 599px) {
  .page-not-found-page .page-content .game-selection .v-btn__content {
    font-size: 20px;
    padding: 20px 0px;
  }
}
@media (max-width: 425px) {
  .page-not-found-page .page-content .game-selection .v-btn__content {
    font-size: 16px;
    padding: 15px 0px;
  }
}
</style>
